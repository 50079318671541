import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import secureAdminAxios from "./secureAdminAxios";
import { EXAM_URL } from "../../constants";
import { styled } from "styled-components";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { isEmpty } from "lodash";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import { useReactToPrint } from "react-to-print";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
  border: solid gray 1px;
  border-radius: 5px;
`;

const H4 = styled.h4`
  margin: 5px;
`;

const StyledTableHeaderRow = styled(TableRow)`
  & th {
    background-color: #9c27b0;
    color: #fff !important;
  }
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f3e5f5;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const ExamReport = ({ examId }) => {
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [batches, setBatches] = useState(["All"]);
  const [selectedBatch, setSelectedBatch] = useState("All");
  const [searchParams] = useSearchParams();
  const selectedExamId = examId ?? searchParams.get("exam_id");
  const printContent = useRef();
  const handlePrint = useReactToPrint({
    content: () => printContent.current,
  });

  const fetchBatches = () => {
    secureAdminAxios
      .get(`${EXAM_URL}/get_batches.php`)
      .then((res) => {
        setBatches(["All", ...res?.data?.map((batch) => batch.batch)]);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchBatches();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedExamId) {
      setLoading(true);
      secureAdminAxios
        .get(
          `${EXAM_URL}/get_exam_details.php?exam_id=${selectedExamId}&batch=${selectedBatch}`
        )
        .then((res) => {
          setResult(res?.data);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  }, [selectedExamId, selectedBatch]);

  return loading ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <div style={{ padding: "10px" }}>
      <ButtonsContainer>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="batch">Batch</InputLabel>
          <Select
            labelId="batch"
            id="batch"
            value={selectedBatch}
            label="Year"
            onChange={(e) => {
              setSelectedBatch(e.target.value);
            }}
          >
            {batches?.map((batch) => (
              <MenuItem value={batch} key={batch}>
                {batch}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          onClick={handlePrint}
          variant="contained"
          color="primary"
          startIcon={<PrintTwoToneIcon />}
        >
          Print
        </Button>
      </ButtonsContainer>
      <div ref={printContent} style={{ padding: "10px" }}>
        <Container>
          <H4>
            Exam Name:{" "}
            <span style={{ color: "#9c27b0" }}>
              {!isEmpty(result) ? result[0]?.exam_name : ""}
            </span>
          </H4>
          <H4>
            Batch: <span style={{ color: "#9c27b0" }}>{selectedBatch}</span>
          </H4>
          <H4>
            Total:{" "}
            <span style={{ color: "#9c27b0" }}>
              {!isEmpty(result) ? result[0]?.total : ""}
            </span>
          </H4>
        </Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <StyledTableHeaderRow>
                <TableCell></TableCell>
                <TableCell align="left">Student Name</TableCell>
                <TableCell align="right">Score</TableCell>
              </StyledTableHeaderRow>
            </TableHead>
            <TableBody>
              {result?.map((row, index) => (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell align="left">{row?.student_name}</TableCell>
                  <TableCell align="right">
                    <b>{row?.score}</b>/{row?.total}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ExamReport;
