import { EXAM } from "./constants";

const exam = (state = {}, { type, payload }) => {
  switch (type) {
    case EXAM:
      return { ...payload };
    default:
      return state;
  }
};

export default exam;
