import { styled } from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReplayCircleFilledTwoToneIcon from "@mui/icons-material/ReplayCircleFilledTwoTone";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import secureAdminAxios from "./secureAdminAxios";
import qs from "qs";
import { EXAM_URL } from "../../constants";
import React, { useState } from "react";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  padding: 10px;
  background-color: #e7ebf0;
  margin-top: 20px;

  & th {
    font-weight: bold;
  }
`;

const Header = styled.h1`
  text-align: center;
  color: red;
  margin-bottom: 20px;
  font-size: larger;
  font-weight: bold;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StudentExams = ({
  student = {},
  exams = [],
  setLoading = () => {},
  fetchStudentDetails = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [reAttemptExam, setReAttemptExam] = useState({});

  const addReAttempt = () => {
    setOpen(false);
    setLoading(true);

    secureAdminAxios
      .post(
        `${EXAM_URL}/add_attempt.php`,
        qs.stringify({
          student_exam_id: reAttemptExam?.student_exam_id,
        })
      )
      .then(() => {
        fetchStudentDetails();
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpen = (exam = {}) => {
    setReAttemptExam(exam);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <Header>Exams</Header>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>Exam Name</TableCell>
              <TableCell align="center">Score</TableCell>
              <TableCell align="center">Total</TableCell>
              <TableCell align="center">Completed</TableCell>
              <TableCell align="center">Re-attempt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exams?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">{row.score}</TableCell>
                <TableCell align="center">{row.total}</TableCell>
                <TableCell align="center">
                  {row.is_completed === "1" ? (
                    <sapn className="color-success">Completed</sapn>
                  ) : (
                    <sapn className="color-error">Incomplete</sapn>
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => handleOpen(row)}
                    variant="contained"
                    color="error"
                    title="Add Re-Attempt"
                    disabled={row.re_attempt !== "1"}
                  >
                    <ReplayCircleFilledTwoToneIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            padding: "10px",
          }}
        >
          <DialogTitle>Add Re-Attempt</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Do you really want to add re-attempt of exam{" "}
              <span className="color-error">{reAttemptExam?.name}</span> to
              student <span className="color-error">{student?.name}</span>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={addReAttempt}
            >
              Add Re-Attempt
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Container>
  );
};

export default StudentExams;
