import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Exam from "./components/Exam";
import Home from "./components/Home";
import Result from "./components/Result";
import ImportQuestions from "./components/Admin/ImportQuestions";
import Exams from "./components/Admin/Exams";
import Students from "./components/Admin/Students";
import Login from "./components/Login/Login";
import AdminLogin from "./components/Admin/Login";
import ExamReport from "./components/Admin/ExamReport";
import ViewDetails from "./components/Result/ViewDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    exact: true,
  },
  {
    path: "/home",
    element: <Home />,
    exact: true,
  },
  {
    path: "/exam",
    element: <Exam />,
    exact: true,
  },
  {
    path: "/result",
    element: <Result />,
    exact: true,
  },
  {
    path: "/exam_details/:studentExamId",
    element: <ViewDetails />,
    exact: true,
  },
  {
    path: "/admin",
    element: <AdminLogin />,
    exact: true,
  },
  {
    path: "/admin/home",
    element: <Exams />,
    exact: true,
  },
  {
    path: "/admin/exam_report",
    element: <ExamReport />,
    exact: true,
  },
  {
    path: "/admin/add-exam",
    element: <ImportQuestions />,
    exact: true,
  },
  {
    path: "/admin/students",
    element: <Students />,
    exact: true,
  },
]);

// eslint-disable-next-line
export default () => {
  const regex = new RegExp("/admin*");
  const location = window.location;

  if (regex.test(location.pathname)) {
    document.title = "Exam - Admin";
  }

  return <RouterProvider router={router} />;
};
