import React, { useState, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { HeaderContainer, Title } from "../CommonComponents";

const TimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const TimeSpan = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #e91e63;
`;

const SubTitle = styled.span`
  font-size: 12px;
  color: #9e9e9e;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: solid gray 1px;
  border-radius: 5px;
  width: 100%;

  & > div {
    flex: 3;
  }

  & > div:nth-child(1) {
    flex: 2;
  }
`;

const H4 = styled.h4`
  margin: 5px;
`;

const secondsToTime = (secs) => {
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    h: ("0" + hours).slice(-2),
    m: ("0" + minutes).slice(-2),
    s: ("0" + seconds).slice(-2),
  };
  return obj;
};

// eslint-disable-next-line
export default () => {
  const totalSeconds = useSelector(
    ({ questions }) => parseInt(questions.length) * 60
  );
  const { student, exam } = useSelector(({ student, exam }) => ({
    student,
    exam,
  }));
  const [remainingSeconds, setRemainingSeconds] = useState(totalSeconds);
  const navigate = useNavigate();
  const timer = useRef();

  useEffect(() => {
    if (remainingSeconds) {
      timer.current = setInterval(() => {
        setRemainingSeconds((prev) => prev - 1);
      }, 1000);

      return () => timer.current && clearInterval(timer.current);
    }

    navigate("/result", {
      replace: true,
    });

    return () => timer.current && clearInterval(timer.current);
    // eslint-disable-next-line
  }, [remainingSeconds]);

  let remainingTime = useMemo(
    () => secondsToTime(remainingSeconds),
    [remainingSeconds]
  );

  let percentRemaining = useMemo(
    () => (0 >= totalSeconds ? 0 : (remainingSeconds / totalSeconds) * 100),
    [remainingSeconds, totalSeconds]
  );

  return (
    <HeaderContainer>
      <Container>
        <div>
          <Title>Vivek Computers</Title>
          <SubTitle>Online Exam</SubTitle>
        </div>
        <TimerContainer>
          <span style={{ color: "#9c27b0" }}>Time Remaining</span>
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: (theme) => theme.palette.grey[400],
              }}
              size={100}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              value={percentRemaining}
              color={percentRemaining < 10 ? "error" : "primary"}
              size={100}
              thickness={4}
              sx={{ position: "absolute", left: 0 }}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TimeSpan>
                {`${remainingTime.h}:${remainingTime.m}:${remainingTime.s}`}
              </TimeSpan>
            </Box>
          </Box>
        </TimerContainer>
        <div>
          <H4>
            Student Name:{" "}
            <span style={{ color: "#9c27b0" }}>{student?.name}</span>
          </H4>
          <H4>
            Exam Name: <span style={{ color: "#9c27b0" }}>{exam?.name}</span>
          </H4>
        </div>
      </Container>
    </HeaderContainer>
  );
};
