import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { styled } from "styled-components";

const Header = styled.h1`
  text-align: center;
  color: red;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: larger;
  font-weight: bold;
`;

const StyledRow = styled(TableRow)`
  & th:nth-child(1),
  & td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #fff;
  }

  & th:nth-child(1) {
    z-index: 4;
  }

  & td:nth-child(1) {
    z-index: 3;
  }
`;

const Questions = ({ questions }) => {
  return isEmpty(questions) ? (
    <></>
  ) : (
    <>
      <Header>Questions</Header>
      <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
          stickyHeader
        >
          <TableHead>
            <StyledRow>
              <TableCell></TableCell>
              <TableCell style={{ minWidth: "400px" }}>Question</TableCell>
              <TableCell>Option A</TableCell>
              <TableCell>Option B</TableCell>
              <TableCell>Option C</TableCell>
              <TableCell>Option D</TableCell>
              <TableCell>Correct Answer</TableCell>
              <TableCell>Question Type</TableCell>
            </StyledRow>
          </TableHead>
          <TableBody>
            {questions.map((row, index) => (
              <StyledRow
                key={row.question}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.question}</TableCell>
                <TableCell>{row.optA}</TableCell>
                <TableCell>{row.optB}</TableCell>
                <TableCell>{row.optC}</TableCell>
                <TableCell>{row.optD}</TableCell>
                <TableCell>{row.correctAns}</TableCell>
                <TableCell>{row.questionType}</TableCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Questions;
