import { styled } from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress, IconButton } from "@mui/material";
import NoteAddTwoToneIcon from "@mui/icons-material/NoteAddTwoTone";
import PeopleTwoToneIcon from "@mui/icons-material/PeopleTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import { useNavigate } from "react-router-dom";
import { EXAM_URL } from "../../constants";
import qs from "qs";
import Questions from "./Questions";
import secureAdminAxios from "./secureAdminAxios";
import Logout from "./Logout";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  background-color: #e7ebf0;
  padding: 10px;
  min-height: 100vh;

  & th {
    font-weight: bold;
  }
`;

const Header = styled.h1`
  text-align: center;
  color: red;
  margin-bottom: 20px;
  font-size: larger;
  font-weight: bold;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const Exams = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState();
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  const fetchExams = () => {
    setLoading(true);
    secureAdminAxios
      .get(`${EXAM_URL}/get_exams.php`)
      .then((res) => {
        setRows(res?.data ?? []);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchExams();
  }, []);

  const handleToggleChange = (e, id) => {
    setLoading(true);
    secureAdminAxios
      .post(
        `${EXAM_URL}/activate_exam.php`,
        qs.stringify({
          id,
          is_active: e.target.checked,
        })
      )
      .then(() => {
        fetchExams();
      })
      .catch((e) => console.log(e));
  };

  const handleClickAddExam = () => {
    navigate("/admin/add-exam");
  };

  const handleClickAddStudents = () => {
    navigate("/admin/students");
  };

  const viewQuestions = (id) => {
    setLoading(true);

    secureAdminAxios
      .get(`${EXAM_URL}/get_questions.php?id=${id}`)
      .then((res) => {
        setSelectedExamId(id);
        setQuestions(res?.data ?? []);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ButtonsContainer style={{ display: "flex" }}>
        <Button
          onClick={handleClickAddStudents}
          variant="contained"
          color="primary"
          startIcon={<PeopleTwoToneIcon />}
        >
          Students
        </Button>
        <Button
          onClick={handleClickAddExam}
          variant="contained"
          color="primary"
          startIcon={<NoteAddTwoToneIcon />}
        >
          Add Exam
        </Button>
        <Logout />
      </ButtonsContainer>

      <Header>Exams</Header>
      <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>Exam Name</TableCell>
              <TableCell align="center">Total Questions</TableCell>
              <TableCell align="right">Activate</TableCell>
              <TableCell align="center">View Questions</TableCell>
              <TableCell align="right">View Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">{row.total_questions}</TableCell>
                <TableCell align="right">
                  <Switch
                    label={row.is_active ? "Deactivate" : "Activate"}
                    checked={row.is_active === "true"}
                    onChange={(e) => handleToggleChange(e, row.id)}
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => viewQuestions(row.id)}
                    variant="contained"
                    color="primary"
                    title="View Questions"
                    disabled={selectedExamId === row.id}
                  >
                    <VisibilityTwoToneIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="right">
                  <a
                    href={`/admin/exam_report?exam_id=${row.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Report
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Questions questions={questions} />
    </Container>
  );
};

export default Exams;
