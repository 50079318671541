import { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import { styled } from "styled-components";
import {
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import { MYALC_URL } from "../../constants";
import { trim } from "lodash";

const BatchContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Container = styled(BatchContainer)`
  flex-flow: column;
  padding: 10px;
`;

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function ImportStudentsLogin({
  open = true,
  setOpen = () => {},
  importStudents = () => {},
}) {
  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return [currentYear + 1, currentYear, currentYear - 1];
  }, []);

  const [centerCode, setCenterCode] = useState("");
  const [password, setPassword] = useState("");
  const [batchMonth, setBatchMonth] = useState(months[0]);
  const [batchYear, setBatchYear] = useState(years[1]);
  const [isValidated, setIsValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setCenterCode("");
    setPassword("");
    setBatchMonth(months[0]);
    setBatchYear(years[1]);
    setIsValidated(false);
    setLoading(false);
    setIsSnackbarOpen(false);
  };

  const validateLogin = () => {
    setIsValidated(true);

    if (
      !centerCode?.length ||
      !password?.length ||
      !batchMonth?.length ||
      !batchYear
    ) {
      return;
    }
    setLoading(true);
    // setIsValidUser(true);

    axios
      .get(
        `${MYALC_URL}/import_students.php?centerCode=${centerCode}&password=${password}&batch=${`${batchMonth} ${batchYear}`}`
      )
      .then((res) => {
        if (!res?.data?.isValid) {
          setIsSnackbarOpen(true);
        } else {
          importStudents(
            res?.data?.students?.map((student) => ({
              ...student,
              name: trim(student?.name),
              username: trim(student?.username),
            })) ?? []
          );
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Inavlid login credentials.
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Login to MyALC</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your MyALC credentials to import students.
          </DialogContentText>
          {isValidated &&
            (!centerCode?.length ||
              !password?.length ||
              !batchMonth?.length ||
              !batchYear) && (
              <Alert severity="error">
                {!centerCode?.length && "Center code is required. "}
                {!password?.length && "Password is required. "}
                {!batchMonth?.length && "Batch month is required. "}
                {!batchYear && "Batch year is required."}
              </Alert>
            )}
          <Container>
            <TextField
              required
              type="text"
              label="Center Code"
              value={centerCode}
              onChange={(e) => {
                setCenterCode(e.target.value);
              }}
              size="small"
            />
            <TextField
              required
              type="password"
              label="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              size="small"
            />
            <BatchContainer>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="month">Month</InputLabel>
                <Select
                  labelId="month"
                  id="month"
                  value={batchMonth}
                  label="Month"
                  onChange={(e) => {
                    setBatchMonth(e.target.value);
                  }}
                >
                  {months.map((month) => (
                    <MenuItem value={month} key={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="year">Year</InputLabel>
                <Select
                  labelId="year"
                  id="year"
                  value={batchYear}
                  label="Year"
                  onChange={(e) => {
                    setBatchYear(e.target.value);
                  }}
                >
                  {years.map((year) => (
                    <MenuItem value={year} key={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </BatchContainer>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Cancel
          </Button>
          <Button
            onClick={validateLogin}
            variant="contained"
            color="primary"
            startIcon={
              <>
                {loading && <CircularProgress size={20} />}
                <DownloadTwoToneIcon />
              </>
            }
            disabled={loading}
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
