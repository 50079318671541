import { styled } from "styled-components";
import { HeaderContainer, Title } from "../CommonComponents";
import Question from "./Question";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import { Button } from "@mui/material";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
  width: 100%;

  & > div {
    flex: 1;
  }
`;

const SubTitle = styled.span`
  font-size: 12px;
  color: #9e9e9e;
`;

const H4 = styled.h4`
  margin: 5px;
`;

const Result = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2 {
    margin: 0;
    color: red;
  }
`;

const ExamDetails = ({
  score = 0,
  total = 0,
  student,
  exam,
  questions,
  handleFinishExam,
}) => (
  <HeaderContainer>
    <Container>
      <div>
        <Title>Vivek Computers</Title>
        <SubTitle>Online Exam</SubTitle>
      </div>
      <Result>
        <Title>Result</Title>
        <h2>{`${score} / ${total}`}</h2>
      </Result>
      <div>
        <H4>
          Student Name:{" "}
          <span style={{ color: "#9c27b0" }}>{student?.name}</span>
        </H4>
        <H4>
          Exam Name: <span style={{ color: "#9c27b0" }}>{exam?.name}</span>
        </H4>
      </div>
    </Container>
    {questions.map((question, index) => (
      <Question
        key={`rq_${question.id}`}
        question={question}
        questionNumber={index + 1}
      />
    ))}
    {handleFinishExam && (
      <Button
        onClick={handleFinishExam}
        variant="contained"
        color="primary"
        endIcon={<CheckCircleTwoToneIcon size={20} />}
      >
        Finish
      </Button>
    )}
  </HeaderContainer>
);

export default ExamDetails;
