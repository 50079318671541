import { STUDENT } from "./constants";

const defaultState = (() => {
  try {
    return JSON.parse(localStorage.getItem("student"));
  } catch (e) {
    return {};
  }
})();

const student = (state = defaultState, { type, payload }) => {
  switch (type) {
    case STUDENT:
      return { ...payload };
    default:
      return state;
  }
};

export default student;
