import React from "react";
import styled from "styled-components";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";

const Flex = styled.div`
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
`;
const Qno = styled.span`
  color: #9c27b0;
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  padding-left: 5px;
`;

const Container = styled.div`
  flex: 25;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: solid #9c27b0 1px;
  border-radius: 10px;
  background-color: #fff;
`;

const QuestionContainer = styled.div``;

const QuestionP = styled.p`
  color: gray;
`;

const SelectedAns = styled.p`
  color: red;
`;

const CorrectAns = styled.p`
  color: green;
`;

// eslint-disable-next-line
export default ({ question, questionNumber }) => {
  return (
    <Flex>
      <Qno>{questionNumber}</Qno>
      <Container>
        <QuestionContainer>
          <QuestionP>Q. {question.question}</QuestionP>
          <SelectedAns>Selected Answer: {question.response}</SelectedAns>
          <CorrectAns>Correct Answer: {question.answer}</CorrectAns>
        </QuestionContainer>
        {question.response == question.answer ? (
          <CheckCircleTwoToneIcon color="success" />
        ) : (
          <CancelTwoToneIcon color="error" />
        )}
      </Container>
    </Flex>
  );
};
