import { styled } from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import GroupAddTwoToneIcon from "@mui/icons-material/GroupAddTwoTone";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import { EXAM_URL } from "../../constants";
import { useReactToPrint } from "react-to-print";
import StudentDetails from "./StudentDetails";
import ImportStudentsLogin from "./ImportStudentsLogin";
import secureAdminAxios from "./secureAdminAxios";
import Logout from "./Logout";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  background-color: #e7ebf0;
  padding: 10px;
  height: 100vh;

  & th {
    font-weight: bold;
  }
`;

const Header = styled.h1`
  text-align: center;
  color: red;
  margin-bottom: 20px;
  font-size: larger;
  font-weight: bold;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const Students = () => {
  const [rows, setRows] = useState([]);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [batches, setBatches] = useState(["All"]);
  const [selectedBatch, setSelectedBatch] = useState("All");
  const [selectedStudentId, setSelectedStudentId] = useState(0);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const printContent = useRef();
  const handlePrint = useReactToPrint({
    content: () => printContent.current,
  });

  const fetchStudents = (batch = selectedBatch) => {
    setLoading(true);

    secureAdminAxios
      .get(`${EXAM_URL}/get_students.php?batch=${batch}`)
      .then((res) => {
        setRows(res?.data ?? []);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const fetchBatches = () => {
    secureAdminAxios
      .get(`${EXAM_URL}/get_batches.php`)
      .then((res) => {
        setBatches(["All", ...res?.data?.map((batch) => batch.batch)]);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchBatches();
    fetchStudents();
    // eslint-disable-next-line
  }, []);

  const handleClickExams = () => {
    navigate("/admin/home");
  };

  const handleClickImportStudents = () => {
    setIsLoginOpen(true);
  };

  const importStudents = (students) => {
    setIsLoginOpen(false);
    setLoading(true);

    secureAdminAxios
      .post(`${EXAM_URL}/insert_students.php`, qs.stringify(students))
      .then(() => {
        fetchBatches();
        fetchStudents();
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const viewStudentDetails = (studentId) => {
    setSelectedStudentId(studentId);
    setOpen(true);
  };

  const handleStudentDetailsClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <ButtonsContainer>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="batch">Batch</InputLabel>
            <Select
              labelId="batch"
              id="batch"
              value={selectedBatch}
              label="Year"
              onChange={(e) => {
                setSelectedBatch(e.target.value);
                fetchStudents(e.target.value);
              }}
            >
              {batches?.map((batch) => (
                <MenuItem value={batch} key={batch}>
                  {batch}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            onClick={handlePrint}
            variant="contained"
            color="primary"
            startIcon={<PrintTwoToneIcon />}
          >
            Print
          </Button>
          <Button
            onClick={handleClickExams}
            variant="contained"
            color="primary"
            startIcon={<DescriptionTwoToneIcon />}
          >
            Exams
          </Button>
          <Button
            onClick={handleClickImportStudents}
            variant="contained"
            color="primary"
            startIcon={<GroupAddTwoToneIcon />}
          >
            Import Students
          </Button>
          <Logout />
        </ButtonsContainer>
        <Header>Students</Header>
        <TableContainer component={Paper} ref={printContent}>
          <Table
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Student Name</TableCell>
                {/* <TableCell align="right">Mobile Number</TableCell> */}
                <TableCell>Batch</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Password</TableCell>
                <TableCell align="center">View Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  {/* <TableCell align="right">{row.mobile}</TableCell> */}
                  <TableCell>{row.batch}</TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.password}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => viewStudentDetails(row.id)}
                      variant="contained"
                      color="primary"
                      title="View Student Details"
                    >
                      <VisibilityTwoToneIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ImportStudentsLogin
          open={isLoginOpen}
          setOpen={setIsLoginOpen}
          importStudents={importStudents}
        />
      </Container>
      <StudentDetails
        open={open}
        handleStudentDetailsClose={handleStudentDetailsClose}
        studentId={selectedStudentId}
      />
    </>
  );
};

export default Students;
