import { EXAM, QUESTIONS, STUDENT } from "./reducers/constants";
import store from "./store";

export const updateQuestions = (questions) => {
  store.dispatch({
    type: QUESTIONS,
    payload: questions,
  });
};

export const updateStudent = (student) => {
  store.dispatch({
    type: STUDENT,
    payload: student,
  });
};

export const updateExam = (exam) => {
  store.dispatch({
    type: EXAM,
    payload: exam,
  });
};
