import { useSelector } from "react-redux";
import ExamDetails from "./ExamDetails";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import secureAxios from "../secureAxios";
import { EXAM_URL } from "../../constants";
import { Backdrop, CircularProgress } from "@mui/material";

const ViewDetails = () => {
  const { student } = useSelector(({ student }) => ({ student }));
  const [exam, setExam] = useState({});
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { studentExamId } = useParams();

  useEffect(() => {
    if (studentExamId) {
      setLoading(true);

      secureAxios
        .get(
          `${EXAM_URL}/get_student_exam_details.php?student_exam_id=${studentExamId}`
        )
        .then((res) => {
          setExam(res?.data?.exam);
          setQuestions(res?.data?.questions);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  }, [studentExamId]);

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ExamDetails
        score={exam?.score}
        total={exam?.total}
        student={student}
        exam={exam}
        questions={questions}
      />
    </>
  );
};

export default ViewDetails;
