import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import Slide from "@mui/material/Slide";
import { styled } from "styled-components";
import { EXAM_URL, MYALC_URL } from "../../constants";
import { Backdrop, CircularProgress } from "@mui/material";
import StudentExams from "./StudentExams";
import secureAdminAxios from "./secureAdminAxios";

const Container = styled.div`
  margin: 0 10px;
`;

const DetailsContainer = styled.div`
  display: grid;
  margin-bottom: 20px;

  & h4:nth-child(odd) {
    grid-column: 1;
  }

  & h4:nth-child(even) {
    grid-column: 2;
  }
`;

const H4 = styled.h4`
  margin: 5px;

  & span {
    color: #9c27b0;
  }
`;

const StudentDetails = ({
  open,
  handleStudentDetailsClose = () => {},
  studentId,
}) => {
  const [loading, setLoading] = useState(false);
  const [studentDetails, setStudentDetails] = useState({});

  const fetchStudentDetails = () => {
    if (open && studentId) {
      setLoading(true);

      secureAdminAxios
        .get(`${EXAM_URL}/get_student_details.php?student_id=${studentId}`)
        .then((res) => {
          setStudentDetails(res?.data);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchStudentDetails();
  }, [open, studentId]);

  const handleOnClose = () => {
    setStudentDetails({});
    handleStudentDetailsClose();
  };

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleOnClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <PersonOutlineTwoToneIcon />
              <span style={{ marginLeft: "5px" }}>Student Details</span>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleOnClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <Container>
            {loading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <DetailsContainer>
              <H4>
                Name: <span>{studentDetails?.student?.name ?? ""}</span>
              </H4>
              <H4>
                Mobile Number:{" "}
                <span>{studentDetails?.student?.mobile ?? ""}</span>
              </H4>
              <H4>
                Batch: <span>{studentDetails?.student?.batch ?? ""}</span>
              </H4>
              <H4>
                Username: <span>{studentDetails?.student?.username ?? ""}</span>
              </H4>
              <H4>
                Password: <span>{studentDetails?.student?.password ?? ""}</span>
              </H4>
              <H4>
                MyalcId:{" "}
                <a
                  href={`${MYALC_URL}/learner_details.php?roll=${studentDetails?.student?.myalc_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {studentDetails?.student?.myalc_id ?? ""}
                </a>
              </H4>
            </DetailsContainer>
            <Divider />
            <StudentExams
              student={studentDetails?.student}
              exams={studentDetails?.exams}
              setLoading={setLoading}
              fetchStudentDetails={fetchStudentDetails}
            />
          </Container>
        </Slide>
      </Dialog>
    </div>
  );
};

export default StudentDetails;
