import axios from "axios";
import { isEmpty } from "lodash";

const redirectToLogin = () => {
  localStorage.removeItem("admin");
  window.location = "/admin";
};

const secureAdminAxios = axios.create();

// Add auth params
secureAdminAxios.defaults.params = {};
secureAdminAxios.interceptors.request.use(
  function (config) {
    try {
      const admin = JSON.parse(localStorage.getItem("admin"));

      if (isEmpty(admin)) {
        redirectToLogin();
      }

      config.params["admin_id"] = admin?.id;
      config.params["token"] = admin?.token;
    } catch (e) {
      redirectToLogin();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a 401 response interceptor
secureAdminAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error?.response?.status) {
      redirectToLogin();
    }

    return Promise.reject(error);
  }
);

export default secureAdminAxios;
