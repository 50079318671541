import React from "react";
import Body from "./Body";
import Header from "./Header";
// import Footer from "./Footer";

// eslint-disable-next-line
export default () => {
  return (
    <>
      <Header />
      <Body />
      {/* <Footer /> */}
    </>
  );
};
