import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Button,
  Checkbox,
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { updateQuestions } from "../../redux/redux";
import { useNavigate } from "react-router";
import { QUESTION_TYPES } from "../../constants";

const QuestionContainer = styled.div`
  flex: 3;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
  margin-right: 5px;
  position: relative;
  padding-bottom: 60px;
  height: 500px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const defaultResponses = {
  optA: false,
  optB: false,
  optC: false,
  optD: false,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// eslint-disable-next-line
export default ({ selectedQuestionId, setSelectedQuestionId }) => {
  const [open, setOpen] = useState(false);
  const questions = useSelector(({ questions }) => [...questions]);
  const navigate = useNavigate();

  let question = false;

  if (questions && questions[selectedQuestionId]) {
    question = { ...questions[selectedQuestionId] };
  }

  const changeSelectedQuestionId = (numberOfSteps) => {
    setSelectedQuestionId(selectedQuestionId + numberOfSteps);
  };

  const updateResponse = (e) => {
    let tempQuestion = { ...question };
    const response =
      question?.question_type == QUESTION_TYPES.MULTI_SELECT &&
      !!tempQuestion?.responseObject
        ? {
            ...tempQuestion.responseObject,
            [e.target.name]: !tempQuestion.responseObject[e.target.name],
          }
        : { ...defaultResponses, [e.target.name]: true };

    tempQuestion.responseObject = response;

    tempQuestion.response = `${
      tempQuestion.responseObject.optA ? `${tempQuestion.optA}, ` : ""
    }${tempQuestion.responseObject.optB ? `${tempQuestion.optB}, ` : ""}${
      tempQuestion.responseObject.optC ? `${tempQuestion.optC}, ` : ""
    }${tempQuestion.responseObject.optD ? `${tempQuestion.optD}` : ""}`
      .replace(/^, */, "")
      .replace(/^, */, "")
      .replace(/^, */, "")
      .replace(/^, */, "")
      .replace(/, *$/, "")
      .replace(/, *$/, "")
      .replace(/, *$/, "")
      .replace(/, *$/, "");

    questions[selectedQuestionId] = tempQuestion;
    updateQuestions(questions);
  };

  const endExam = () => {
    navigate("/result", {
      replace: true,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getComponent = () => {
    if (question?.question_type == QUESTION_TYPES.MULTI_SELECT) {
      return <Checkbox />;
    }

    return <Radio />;
  };

  if (!question) {
    return null;
  }

  return (
    <QuestionContainer>
      <FormLabel component="legend">Q. {question?.question}</FormLabel>
      <br />
      <hr />
      <br />
      <RadioGroup aria-label="quiz" name="quiz">
        {!!question?.optA?.length && (
          <FormControlLabel
            name="optA"
            value={question.optA}
            control={getComponent()}
            label={question.optA}
            // eslint-disable-next-line
            checked={!!question?.responseObject?.optA}
            onChange={updateResponse}
          />
        )}
        {!!question?.optB?.length && (
          <FormControlLabel
            name="optB"
            value={question.optB}
            control={getComponent()}
            label={question.optB}
            // eslint-disable-next-line
            checked={!!question?.responseObject?.optB}
            onChange={updateResponse}
          />
        )}
        {!!question?.optC?.length && (
          <FormControlLabel
            name="optC"
            value={question.optC}
            control={getComponent()}
            label={question.optC}
            // eslint-disable-next-line
            checked={!!question?.responseObject?.optC}
            onChange={updateResponse}
          />
        )}
        {!!question?.optD?.length && (
          <FormControlLabel
            name="optD"
            value={question.optD}
            control={getComponent()}
            label={question.optD}
            // eslint-disable-next-line
            checked={!!question?.responseObject?.optD}
            onChange={updateResponse}
          />
        )}
      </RadioGroup>
      <ButtonContainer>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={0 === selectedQuestionId}
          onClick={() => changeSelectedQuestionId(-1)}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={questions.length - 1 === selectedQuestionId}
          onClick={() => changeSelectedQuestionId(1)}
        >
          Next
        </Button>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => setOpen(true)}
        >
          End Exam
        </Button>
      </ButtonContainer>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            padding: "10px",
          }}
        >
          <DialogTitle>End Exam</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Do you really want to end exam?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={endExam}
            >
              End Exam
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </QuestionContainer>
  );
};
