import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
} from "@mui/material";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { EXAM_URL } from "../../constants";
import secureAxios from "../secureAxios";
import qs from "qs";
import { isEmpty, shuffle } from "lodash";
import { updateExam, updateQuestions } from "../../redux/redux";
import Logout from "../Login/Logout";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExamContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
  box-shadow: 5px 7px 15px -7px #888888;
  border: 1px solid #888888;
`;

const GridContainer = styled.div`
  max-height: calc(100vh - 130px);
  overflow: auto;
`;

const ExamGridContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 1fr 24px;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: solid gray 1px;
  border-radius: 5px;
  width: calc(100vw - 50px);
  margin: 5px auto;
`;

// eslint-disable-next-line
export default () => {
  const [loading, setLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();
  const { student } = useSelector(({ student }) => ({
    student,
  }));

  useEffect(() => {
    if (student?.id) {
      setLoading(true);

      secureAxios
        .get(`${EXAM_URL}/get_student_exams.php`)
        .then((res) => {
          if (!isEmpty(res?.data)) {
            const tempExams = res?.data ?? [];
            setExams(tempExams);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [student?.id]);

  const gotoExam = (exam) => {
    setLoading(true);
    secureAxios
      .post(
        `${EXAM_URL}/start_exam.php`,
        qs.stringify({
          student_exam_id: exam?.student_exam_id,
        })
      )
      .then((res) => {
        const questions = res?.data ?? [];
        if (!isEmpty(questions)) {
          updateExam({
            ...exam,
            student_exam_id: questions[0]["student_exam_id"],
          });
          updateQuestions(shuffle(questions));
          navigate("/exam");
        } else {
          setIsSnackbarOpen(true);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Unable to start exam.
        </Alert>
      </Snackbar>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <HomeContainer>
        <ExamContainer>
          <h1>Welcome, {student?.name}</h1>
          <Logout />
        </ExamContainer>
        <GridContainer>
          {exams?.map((exam) => (
            <ExamGridContainer key={exam?.student_exam_id ?? "active"}>
              <h3>
                Exam Name:{" "}
                <span style={{ color: "#9c27b0" }}>{exam?.name}</span>
              </h3>
              <h3>
                Total Questions:{" "}
                <span style={{ color: "#9c27b0" }}>
                  {exam?.total_questions}
                </span>
              </h3>
              {exam?.is_completed === "1" ? (
                <h3>
                  Score: <span style={{ color: "#9c27b0" }}>{exam?.score}</span>
                </h3>
              ) : (
                <Button
                  onClick={() => gotoExam(exam)}
                  variant="contained"
                  color="primary"
                  endIcon={<SendTwoToneIcon />}
                  disabled={isEmpty(exam)}
                >
                  Start Exam
                </Button>
              )}
              {exam?.is_completed === "1" && (
                <IconButton
                  onClick={() =>
                    window.open(`/exam_details/${exam.student_exam_id}`)
                  }
                  variant="contained"
                  color="primary"
                  title="View Details"
                >
                  <VisibilityTwoToneIcon />
                </IconButton>
              )}
            </ExamGridContainer>
          ))}
        </GridContainer>
      </HomeContainer>
    </>
  );
};
