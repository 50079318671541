import { Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import qs from "qs";
import { useState } from "react";
import { styled } from "styled-components";
import { EXAM_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import { updateStudent } from "../../redux/redux";

const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-flow: column;
  padding: 10px;
  width: 500px;
  margin: 0 auto;
`;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleClickLogin = () => {
    if (!username?.length || !password?.length) {
      return;
    }

    setLoading(true);

    axios
      .post(`${EXAM_URL}/get_token.php`, qs.stringify({ username, password }))
      .then((res) => {
        const data = res?.data;
        if (data?.isValid) {
          localStorage.setItem("student", JSON.stringify(data?.student));
          updateStudent(data?.student);
          navigate("/home");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <h1>Login to Exam</h1>
      <TextField
        required
        type="text"
        label="Username"
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
        size="small"
        autoComplete="off"
      />
      <TextField
        required
        type="password"
        label="Password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        size="small"
        autoComplete="off"
      />
      <Button
        onClick={handleClickLogin}
        variant="contained"
        color="primary"
        startIcon={<>{loading && <CircularProgress size={20} />}</>}
        disabled={loading}
      >
        Login
      </Button>
    </Container>
  );
};

export default Login;
