import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
} from "@mui/material";
import { styled } from "styled-components";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import Questions from "./Questions";
import qs from "qs";
import { EXAM_URL } from "../../constants";
import secureAdminAxios from "./secureAdminAxios";
import template from "./exam_questions_template.xlsx";

const Container = styled.div`
  background-color: #e7ebf0;
  padding: 10px;
  height: 100vh;
`;

const Header = styled.h1`
  text-align: center;
  color: red;
  margin-bottom: 20px;
  font-size: larger;
  font-weight: bold;
`;

const ErrorMessage = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #d32f2f;
`;

const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  align-self: flex-start;
  gap: 10px;
  width: 100%;
  justify-content: space-between;

  & a {
    color: blue;
  }
`;

const fields = [
  {
    // Visible in table header and when matching columns.
    label: "Question",
    // This is the key used for this field when we call onSubmit.
    key: "question",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["Question", "questions", "Questions"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "कोणतीही वेबसाईट चालविताना युजरला ....... हे एंटर करावे लागते.",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "Question is required.",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  {
    label: "Option A",
    key: "optA",
    alternateMatches: ["OptA", "opt A", "Opt A", "Option A"],
    fieldType: {
      type: "input",
    },
    example: "युआरएल",
    validations: [
      {
        rule: "required",
        errorMessage: "Option A is required.",
        level: "error",
      },
    ],
  },
  {
    label: "Option B",
    key: "optB",
    alternateMatches: ["OptB", "opt B", "Opt B", "Option B"],
    fieldType: {
      type: "input",
    },
    example: "डब्ल्यु डब्ल्यु डब्ल्यु",
    validations: [
      {
        rule: "required",
        errorMessage: "Option B is required.",
        level: "error",
      },
    ],
  },
  {
    label: "Option C",
    key: "optC",
    alternateMatches: ["OptC", "opt C", "Opt C", "Option C"],
    fieldType: {
      type: "input",
    },
    example: "पीपीपी",
  },
  {
    label: "Option D",
    key: "optD",
    alternateMatches: ["OptD", "opt D", "Opt D", "Option D"],
    fieldType: {
      type: "input",
    },
    example: "एच.टी.एम. एल.",
  },
  {
    label: "Answer",
    key: "correctAns",
    alternateMatches: ["answer", "correct answer", "Correct Answer", "Answer"],
    fieldType: {
      type: "input",
    },
    example: "युआरएल",
    validations: [
      {
        rule: "required",
        errorMessage: "Answer is required.",
        level: "error",
      },
    ],
  },
  {
    label: "Quetion Type",
    key: "questionType",
    alternateMatches: [
      "questionType",
      "quetion type",
      "Quetion type",
      "quetion Type",
    ],
    fieldType: {
      type: "input",
    },
    example: "multi-choice",
    validations: [
      {
        rule: "required",
        errorMessage: "Quetion Type is required.",
        level: "error",
      },
    ],
  },
];

const validationSchema = yup.object().shape({
  examName: yup.string().required("Exam name is required."),
  questions: yup.array().min(1, "Please upload questions."),
});

const initialValues = {
  examName: "",
  questions: [],
};

const ImportQuestions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUploadQuestions = (data) => {
    setFieldTouched("questions", true, true);
    setFieldValue("questions", data?.validData ?? []);
  };

  const handleSubmitExam = (values) => {
    setLoading(true);
    secureAdminAxios
      .post(`${EXAM_URL}/insert_exam.php`, qs.stringify(values))
      .then(() => {
        navigate("/admin/home");
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: false,
    onSubmit: handleSubmitExam,
  });

  const { values, touched, setFieldValue, setFieldTouched, handleSubmit } =
    formik;

  return (
    <Container>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Card>
        <CardContent
          style={{
            display: "flex",
            flexFlow: "column",
          }}
        >
          <Button
            onClick={() => navigate(-1)}
            variant="contained"
            color="primary"
            startIcon={<KeyboardBackspaceTwoToneIcon />}
            style={{
              alignSelf: "flex-start",
            }}
          >
            Go Back
          </Button>
          <Header>Add Exam</Header>
          <TextField
            required
            type="text"
            label="Exam Name"
            value={values?.examName}
            onChange={(e) => {
              setFieldTouched("examName", true, true);
              setFieldValue("examName", e.target.value, true);
            }}
            onBlur={() => setFieldTouched("examName", true, true)}
            helperText={
              touched?.examName && !values?.examName?.length
                ? "Exam name is required."
                : ""
            }
            error={!!touched?.examName && !values?.examName?.length}
          />
          <UploadContainer>
            <Button
              onClick={() => setIsOpen(true)}
              variant="contained"
              color="primary"
              startIcon={<UploadTwoToneIcon />}
            >
              Upload Questions
            </Button>
            <span>Total questions: {values?.questions?.length}</span>
            <a href={template} download={true}>
              Download Template
            </a>
          </UploadContainer>
          {touched?.questions && !values?.questions?.length && (
            <ErrorMessage>Please upload questions.</ErrorMessage>
          )}
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            style={{
              marginTop: "20px",
              alignSelf: "center",
            }}
          >
            Submit
          </Button>
          <ReactSpreadsheetImport
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onSubmit={handleUploadQuestions}
            fields={fields}
          />
        </CardContent>
      </Card>
      <Questions questions={values?.questions} />
    </Container>
  );
};

export default ImportQuestions;
