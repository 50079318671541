import axios from "axios";
import { updateStudent } from "../redux/redux";
import { isEmpty } from "lodash";

const redirectToLogin = () => {
  updateStudent({});
  localStorage.removeItem("student");
  window.location = "/";
};

const secureAxios = axios.create();

// Add auth params
secureAxios.defaults.params = {};
secureAxios.interceptors.request.use(
  function (config) {
    try {
      const student = JSON.parse(localStorage.getItem("student"));

      if (isEmpty(student)) {
        redirectToLogin();
      }

      config.params["student_id"] = student?.id;
      config.params["token"] = student?.token;
    } catch (e) {
      redirectToLogin();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a 401 response interceptor
secureAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error?.response?.status) {
      redirectToLogin();
    }

    return Promise.reject(error);
  }
);

export default secureAxios;
