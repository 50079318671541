import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Backdrop, CircularProgress } from "@mui/material";
import secureAxios from "../secureAxios";
import qs from "qs";
import { EXAM_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import ExamDetails from "./ExamDetails";

// eslint-disable-next-line
export default () => {
  const [score, setScore] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { questions, student, exam } = useSelector(
    ({ questions, student, exam }) => ({ questions, student, exam })
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(questions)) {
      let tempTotal = questions.length;
      let tempScore = questions.reduce(
        (pre, question) =>
          question.response == question.answer ? pre + 1 : pre,
        0
      );
      setTotal(tempTotal);
      setScore(tempScore);

      setLoading(true);

      secureAxios
        .post(
          `${EXAM_URL}/end_exam.php`,
          qs.stringify({
            student_exam_id: questions[0]["student_exam_id"],
            score: tempScore,
            questions: questions.map((question) => ({
              question_id: question.id,
              response: question.response ?? "",
            })),
          })
        )
        .then((res) => {
          // console.log(res);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  }, [questions]);

  const handleFinishExam = () => {
    navigate("/home", {
      replace: true,
    });
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ExamDetails
        score={score}
        total={total}
        student={student}
        exam={exam}
        questions={questions}
        handleFinishExam={handleFinishExam}
      />
    </>
  );
};
